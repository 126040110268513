import { useTheme } from '@mui/material/styles'
import { useRouter } from 'next/router'
import Typography from '../Typography'
import Box from '../Box'
import { EventStatus as EventStatusType } from '../Content/interface'
import { useTranslation } from 'react-i18next'

const EventStatus = ({
  eventStatusName,
}: {
  eventStatusName: EventStatusType
}) => {
  const { t } = useTranslation()
  const theme = useTheme()
  const router = useRouter()

  const isLibrary = router.pathname === '/library'

  if (!eventStatusName) {
    return null
  }

  let eventStatusColor = ''
  let eventStatusLabel = ''

  if (eventStatusName) {
    switch (eventStatusName) {
      case 'coming_soon':
        eventStatusLabel = t('eventLabelUpcoming')
        eventStatusColor = theme.palette.blue[500]
        break
      case 'available':
      case 'upcoming':
        eventStatusColor = theme.palette.green[500]
        eventStatusLabel = isLibrary
          ? t('eventLabelUpcoming')
          : t('eventLabelTicketAvailable')
        break
      case 'run_out_soon':
      case 'fully_booked':
        eventStatusColor = theme.palette.orange[500]
        eventStatusLabel = t('eventLabelRunOutSoon')
        break
      case 'sold_out':
        eventStatusColor = theme.palette.purple[500]
        eventStatusLabel = t('eventLabelSoldOut')
        break
      case 'sale_ended':
        eventStatusColor = theme.palette.red[500]
        eventStatusLabel = t('eventLabelSalesEnded')
        break
      case 'event_ended':
        eventStatusColor = theme.palette.grey[800]
        eventStatusLabel = t('eventLabelEventEnded')
        break
    }
  }

  return (
    <Box
      sx={{
        background: eventStatusColor,
        textAlign: 'center',
        borderBottomLeftRadius: '12px',
        borderBottomRightRadius: '12px',
        paddingY: '4px',
      }}
    >
      <Typography
        sx={{ color: 'white' }}
        fontSize={'12px'}
        fontWeight={'bold'}
      >
        {eventStatusLabel}
      </Typography>
    </Box>
  )
}

export default EventStatus
