import imageResizer from 'helpers/image-resizer'
import appConfig from 'configs/app'
import { productType } from 'constants/common'

interface IHandleErrorImageProps {
  currentTarget: EventTarget & HTMLImageElement
  type:
    | 'creator'
    | 'session'
    | 'content'
    | 'event'
    | 'profile'
    | 'default'
    | productType
}

const handleErrorImage = ({
  currentTarget,
  type,
}: IHandleErrorImageProps) => {
  let placeholder = ''
  switch (type) {
    case 'session':
      placeholder = `${appConfig.assetBaseUrl}/placeholder/session-thumbnail.png`
      break
    case 'creator':
      placeholder = `${appConfig.assetBaseUrl}/placeholder/creator-thumbnail.png`
      break
    case 'profile':
      placeholder = `${appConfig.assetBaseUrl}/profile-thumbnail.svg`
      break
    case 'event':
    case 'content':
    default:
      placeholder = `${appConfig.assetBaseUrl}/placeholder/default-thumbnail.png`
      break
  }
  currentTarget.onerror = null
  currentTarget.src = imageResizer(placeholder, '300w')
  return currentTarget
}

export default handleErrorImage
