import { useState, useRef, useEffect } from 'react'
import { styled } from '@mui/material/styles'
import VideoJS from 'components/commons/VideoJS'
import Loader from 'components/commons/Layout/Loader'
import DCVideoPlayerError from '../../ContentVideoPlayerError'
import CloseIcon from '@mui/icons-material/Close'
import Box from 'components/commons/Box'

const StyledIcon = styled(CloseIcon)(({ theme }) => ({
  position: 'absolute',
  right: '1rem',
  top: '1rem',
  color: theme.palette.text.secondary,
  cursor: 'pointer',
  zIndex: 1,
  [theme.breakpoints.up('sm')]: {
    right: '-1.5rem',
    top: '-1.5rem',
  },
}))

function DCVideoPlayer({
  videoUrl,
  iframeUrl,
  iframeUrlError,
  showVideoPlayer,
  type,
  onPlay,
  onPause,
  onFullscreen,
  onPip,
  onBack,
  handleClose,
}) {
  const playerRef = useRef(null)
  const [videoDuration, setVideoDuration] = useState(0)
  const [currentTime, setCurrentTime] = useState(0)
  const [isLoading, setIsLoading] = useState(true)
  const [isIframePlayable, setIsIframePlayable] = useState(false)

  const handlePlayerReady = (player) => {
    playerRef.current = player

    let callbackProps = {
      videoDuration: 0,
    }

    player.on('loadedmetadata', () => {
      const duration = player.duration()
      setVideoDuration(duration)
      callbackProps.videoDuration = duration
    })
    player.on('timeupdate', () => {
      setCurrentTime(player.currentTime())
    })
    player.on('playing', () => {
      onPlay({
        ...callbackProps,
        currentTime: player.currentTime(),
      })
    })
    player.on('pause', () => {
      onPause({
        ...callbackProps,
        currentTime: player.currentTime(),
      })
    })
    player.on('fullscreenchange', () => {
      onFullscreen({
        ...callbackProps,
        currentTime: player.currentTime(),
      })
    })
    player.on('enterpictureinpicture', () => {
      onPip({
        ...callbackProps,
        currentTime: player.currentTime(),
      })
    })
  }

  useEffect(() => {
    if (iframeUrlError) {
      setIsIframePlayable(false)
      setIsLoading(false)
    }
  }, [iframeUrlError])

  return (
    <>
      <StyledIcon
        onClick={() => {
          handleClose()
          setIsLoading(true)
          setIsIframePlayable(false)
          onBack({
            videoDuration: videoDuration,
            currentTime: currentTime,
          })
        }}
      />

      {showVideoPlayer && (
        <Box
          flex="1"
          height="100%"
          maxHeight={{
            sm: '405px',
          }}
        >
          {type === 'full' ? (
            <>
              {isLoading && <Loader height="300px" />}
              {!isLoading && !isIframePlayable && (
                <DCVideoPlayerError
                  onButtonAction={() => {
                    window.location.reload()
                  }}
                />
              )}
              <iframe
                className="sproutvideo-player"
                src={iframeUrl}
                width="100%"
                height="100%"
                frameBorder="0"
                allowFullScreen
                onLoad={() => {
                  setIsIframePlayable(true)
                  setIsLoading(false)
                }}
                referrerPolicy="no-referrer-when-downgrade"
                title="Video Player"
                style={
                  isLoading || !isIframePlayable
                    ? { display: 'none' }
                    : {}
                }
              />
            </>
          ) : (
            <VideoJS
              options={{
                autoplay: true,
                controls: true,
                responsive: true,
                fluid: true,
                sources: [
                  {
                    src: videoUrl,
                    type: 'video/mp4',
                  },
                ],
              }}
              onReady={handlePlayerReady}
              styles={{
                padding: 0,
                height: '100%',
              }}
            />
          )}
        </Box>
      )}
    </>
  )
}

DCVideoPlayer.defaultProps = {
  hideHeader: false,
  onBack: () => {},
  onPlay: () => {},
  onPause: () => {},
  onFullscreen: () => {},
  onPip: () => {},
}

export default DCVideoPlayer
