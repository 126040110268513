import { useState } from 'react'
import { Grid, Modal } from '@mui/material'
import { styled } from '@mui/material/styles'
import { useTheme } from '@mui/material/styles'
import { useStoreActions, useStoreState } from 'stores/hooks'
import DCVideoPlayer from 'components/domains/Content/ContentVideoPlayer/v2'
import { trackEvent } from 'helpers/analytics'
import CloseIcon from '@mui/icons-material/Close'
interface IContentModal {
  isOpen: boolean
  setOpen: Function
  teaser?: string
  poster?: string
  showVideoPlayer?: boolean
  isTeaserShown?: boolean
  trackEventDefaultStates?: Object
  onCloseVideoPlayer?: Function
  setIsTeaserShown?: Function
}

const StyledContainer = styled(Grid)(({ theme }) => ({
  background: theme.palette.background.primary,
  position: 'absolute',
  left: '50%',
  top: '50%',
  transform: 'translate(-50%, -50%)',
  width: '100%',
  height: '100%',
  margin: 'auto 0',
  [theme.breakpoints.up('sm')]: {
    top: '45%',
    maxHeight: '600px',
    width: '75%',
    height: '50%',
    background: theme.palette.background.secondary,
    borderRadius: '16px 16px 0px 0px',
  },
  [theme.breakpoints.up('md')]: {
    width: '50%',
    height: '75%',
  },
}))

const StyledContainerDetail = styled(Grid)(({ theme }) => ({
  borderTopLeftRadius: '16px',
  borderTopRightRadius: '16px',
  padding: '160px 0',
  [theme.breakpoints.up('sm')]: {
    padding: '37px 0',
  },
  [theme.breakpoints.up('md')]: {
    padding: '84px 0',
  },
  [theme.breakpoints.up('lg')]: {
    padding: '97px 0',
  },
}))

const StyledContainerList = styled(Grid)(({ theme }) => ({
  background: theme.palette.background.primary,
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  padding: '8px 0',
  height: '80px',
  position: 'absolute',
  left: 0,
  bottom: 0,
  width: '100%',
  [theme.breakpoints.up('sm')]: {
    bottom: '-112px',
    borderRadius: '0px 0px 16px 16px',
    height: '112px',
    padding: '16px 0',
  },
  [theme.breakpoints.up('md')]: {
    bottom: '-120px',
    height: '120px',
  },
}))

const StyledImgDetail = styled('img')(() => ({
  width: '100%',
  objectFit: 'contain',
  maxHeight: '100%',
}))

const StyledImgThumbnail = styled('img')(({ theme }) => ({
  width: '58px',
  height: '58px',
  cursor: 'pointer',
  objectFit: 'cover',
  marginRight: '24px',
  borderRadius: '10px',
  boxSizing: 'border-box',
  border: `4px solid ${theme.palette.background.secondary}`,
  overflow: 'clip',
  [theme.breakpoints.up('sm')]: {
    width: '80px',
    height: '80px',
  },
}))

const StyledVideoThumbnail = styled('video')(({ theme }) => ({
  width: '58px',
  height: '58px',
  cursor: 'pointer',
  objectFit: 'cover',
  marginRight: '24px',
  borderRadius: '10px',
  boxSizing: 'border-box',
  border: `4px solid ${theme.palette.background.secondary}`,
  overflow: 'clip',
  [theme.breakpoints.up('sm')]: {
    width: '80px',
    height: '80px',
  },
}))

const StyledIcon = styled(CloseIcon)(({ theme }) => ({
  position: 'absolute',
  right: '1rem',
  top: '1rem',
  color: theme.palette.primary.main,
  cursor: 'pointer',
  zIndex: 1,
  [theme.breakpoints.up('sm')]: {
    right: '-1.5rem',
    top: '-1.5rem',
  },
}))

export default function ContentModal({
  isOpen,
  setOpen = () => {},
  teaser,
  poster,
  showVideoPlayer,
  isTeaserShown,
  setIsTeaserShown = () => {},
  trackEventDefaultStates,
  onCloseVideoPlayer,
}: IContentModal) {
  const handleClose = () => setOpen(false)
  const theme = useTheme()
  const [selectedContent, setSelectedContent] = useState(
    teaser ? 'video' : poster ? 'img' : '',
  )

  const { iframeUrl, isIframeUrlError } = useStoreState(
    (state) => state.contentDetail,
  )
  const { setIsIframeUrlError } = useStoreActions(
    (action) => action.contentDetail,
  )

  const videoType = () => {
    if (showVideoPlayer) return 'full'
    else if (isTeaserShown) return 'teaser'
    return ''
  }

  const PlayDetailContent = () => {
    if (selectedContent === 'img') {
      return (
        <>
          <StyledIcon onClick={handleClose} />
          <StyledImgDetail src={poster} />
        </>
      )
    }
    return (
      <DCVideoPlayer
        iframeUrl={iframeUrl}
        iframeUrlError={isIframeUrlError}
        showVideoPlayer={showVideoPlayer || isTeaserShown}
        type={videoType()}
        handleClose={handleClose}
        videoUrl={teaser}
        onBack={(callbackProps) => {
          trackEvent('back_from_teaser', {
            ...trackEventDefaultStates,
            content_teaser_duration: callbackProps.videoDuration,
            content_teaser_on_second: callbackProps.currentTime,
          })
          setIsIframeUrlError(false)
          setIsTeaserShown(false)
          onCloseVideoPlayer()
        }}
        onPlay={(callbackProps) => {
          trackEvent('play_teaser', {
            ...trackEventDefaultStates,
            content_teaser_duration: callbackProps.videoDuration,
            content_teaser_on_second: callbackProps.currentTime,
          })
        }}
        onPause={(callbackProps) => {
          trackEvent('pause_teaser', {
            ...trackEventDefaultStates,
            content_teaser_duration: callbackProps.videoDuration,
            content_teaser_on_second: callbackProps.currentTime,
          })
        }}
        onFullscreen={(callbackProps) => {
          trackEvent('maximize_screen_teaser', {
            ...trackEventDefaultStates,
            content_teaser_duration: callbackProps.videoDuration,
            content_teaser_on_second: callbackProps.currentTime,
          })
        }}
        onPip={(callbackProps) => {
          trackEvent('minimize_screen_teaser', {
            ...trackEventDefaultStates,
            content_teaser_duration: callbackProps.videoDuration,
            content_teaser_on_second: callbackProps.currentTime,
          })
        }}
      />
    )
  }

  return (
    <Modal open={isOpen} onClose={handleClose}>
      <StyledContainer container>
        <StyledContainerDetail
          item
          xs={12}
          sx={{
            display: 'flex',
            alignItems: 'center',
            padding: '0px !important',
            backgroundColor: `${theme.palette.background.tertiery} !important`,
          }}
          maxHeight={{
            xs: 'calc(100% - 80px)',
            sm: '100%',
          }}
        >
          <PlayDetailContent />
        </StyledContainerDetail>
        <StyledContainerList item xs={12}>
          {teaser && (
            <StyledVideoThumbnail
              muted
              onClick={() => setSelectedContent('video')}
              sx={{
                border:
                  selectedContent === 'video' &&
                  `4px solid ${theme.palette.tiptip[500]}`,
              }}
            >
              <source src={`${teaser}#t=0.1`} type="video/mp4" />
            </StyledVideoThumbnail>
          )}
          {poster && (
            <StyledImgThumbnail
              src={poster}
              onClick={() => setSelectedContent('img')}
              sx={{
                border:
                  selectedContent === 'img' &&
                  `4px solid ${theme.palette.tiptip[500]}`,
              }}
            />
          )}
        </StyledContainerList>
      </StyledContainer>
    </Modal>
  )
}
