import { styled } from '@mui/material/styles'
import ErrorIcon from 'assets/svg/session/session-unload.svg'
import Box from 'components/commons/Box'
import Button from 'components/commons/Button'
import Stack from 'components/commons/Stack'
import Typography from 'components/commons/Typography'

const StyledContainer = styled(Box)(({ theme }) => ({
  padding: '0% 16% 0 16%',
  textAlign: 'center',
  color: 'white',
  svg: {
    color: theme.palette.info.main,
    fontSize: 50,
  },
}))

const StyledErrorIconIcon = styled(ErrorIcon)({
  marginBottom: '16px',
})

const StyledButton = styled(Button)({
  borderRadius: '18px',
  padding: '6px 28.5px',
})

const StyledStack = styled(Stack)({
  marginTop: '24px',
})

function DCVideoPlayerError({ onButtonAction }) {
  return (
    <StyledContainer>
      <StyledErrorIconIcon />
      <Typography fontSize="20px" fontWeight="700">
        Gagal menampilkan video
      </Typography>
      <Typography color="#secondary" fontSize="14px" fontWeight="400">
        Terjadi kesalahan dalam menampilkan video, mohon refresh untuk
        memuat ulang.
      </Typography>
      <StyledStack
        direction="row"
        justifyContent="center"
        alignItems="center"
        spacing={2}
      >
        <StyledButton
          buttonVariant="outlined"
          onClick={onButtonAction}
        >
          Refresh
        </StyledButton>
      </StyledStack>
    </StyledContainer>
  )
}

export default DCVideoPlayerError
