import { styled } from '@mui/material'
import PlayTeaserIcon from '@mui/icons-material/PlayCircleFilled'

export const StyledButtonPlay = styled(PlayTeaserIcon)({
  fontSize: '80px',
  objectFit: 'cover',
  color: 'white',
  borderRadius: '50%',
  background: 'rgba(20, 18, 32, 0.5)',
})
